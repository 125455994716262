import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBannerResponse, IState } from '@models';
import { Store } from '@ngrx/store';
import { BannersRequestAction } from '@store/reducers/banners';

@Injectable({
  providedIn: 'root',
})
export class BannersService {
  constructor(private http: HttpClient, private store: Store<IState>) {}

  update() {
    this.store.dispatch(BannersRequestAction());
  }

  getAll() {
    return this.http.get<IBannerResponse>('banners');
  }
}
